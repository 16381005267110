//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
  },
  props: {
    //团队
    teamValue: {
      type: Number,
      default: null,
    },
    selMem: {
      type: Array,
      default: null,
    },
    generaId: {
      type: Number,
      default: null,
    },
    //特殊的日期数据
    selSpecialDayData: {
      type: Object,
      default: null,
    },
    //时薪选项
    wageOptions: {
      type: Array,
      default: null,
    },
    //要设置的天
    selSpecialDay: {
      type: String,
      default: null,
    },
    timeZonesOptions: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      saveBtnLoading: false,
      cancleLoading: false,
      specialForm: {
        specialType: true, //假期、上班选项
        timeZone: "China Standard Time", //时区
        timeInterval: [{}],
        startWork: null, //上班
        endWork: null, //下班
        LastCheckOutTime: null, //最晚下班时间
        lateWork: null, //上班晚到
        lateWorkRadio: null, //上班晚到条件选择
        leaveEarly: null, //下班早走
        leaveEarlyRadio: null, //下班早走条件选择
        hourlyWage: null,
        hourlyWageOver: null,
        IsMorrow: false,
        seriousLateMinute: null, //严重迟到
        seriousLateMinuteCheck: false,
        seriousQuitMinute: null, //严重早退
        seriousQuitMinuteCheck: false,
        absenteeismLateMinute: null, //视为迟到
        absenteeismQuitMinute: null, //视为旷工
        absenteeismMinuteCheck: false,
      },
    };
  },
  methods: {
    /**
     * 清除设置
     */
    handleCancle() {
      this.$confirm("此操作将清除该设置, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.cancleLoading = true;
          this.$http
            .post("/Attendance/PlanBC/DelSpecialPlanBC.ashx", {
              teamId: this.teamValue,
              UserId:
                this.selMem && this.selMem.length
                  ? this.selMem.map((m) => m.UsId)
                  : this.generaId
                  ? this.generaId
                  : null,
              date: this.selSpecialDay,
            })
            .then((resp) => {
              if (resp.res == 0) {
                this.$message({
                  showClose: true,
                  message: "清除成功",
                  type: "success",
                });
                this.$emit("success");
                this.$modal.hide("special");
              }
            })
            .finally(() => (this.cancleLoading = false));
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    /**
     * 排班保存
     */
    handleSave() {
      this.saveBtnLoading = true;
      const data = {
        PlanType: this.generaId || this.selMem.length ? 2 : 1,
        UserId:
          this.selMem && this.selMem.length
            ? this.selMem.map((m) => m.UsId)
            : this.generaId
            ? this.generaId
            : null,
        TimeZone: this.specialForm.timeZone,
        PlanDay: this.selSpecialDay,
        CheckInTime: this.specialForm.startWork
          ? this.specialForm.startWork.timeFormat("yyyy-MM-dd HH:mm:ss")
          : null,
        CheckOutTime: this.specialForm.endWork
          ? this.specialForm.endWork.timeFormat("yyyy-MM-dd HH:mm:ss")
          : null,
        CheckInDuration: this.specialForm.lateWork,
        CheckOutDuration: this.specialForm.leaveEarly,
        IsSetCheckInElasticity: !this.specialForm.lateWorkRadio
          ? null
          : this.specialForm.lateWorkRadio == 1
          ? true
          : false,
        IsSetCheckInHumanization: !this.specialForm.lateWorkRadio
          ? null
          : this.specialForm.lateWorkRadio == 2
          ? true
          : false,
        IsSetCheckOutElasticity: !this.specialForm.leaveEarlyRadio
          ? null
          : this.specialForm.leaveEarlyRadio == 1
          ? true
          : false,
        IsSetCheckOutHumanization: !this.specialForm.leaveEarlyRadio
          ? null
          : this.specialForm.leaveEarlyRadio == 2
          ? true
          : false,
        FreeTime: this.specialForm.timeInterval.map((m) => {
          return {
            StartTime: m.start
              ? m.start.timeFormat("yyyy-MM-dd HH:mm:ss")
              : null,
            EndTime: m.end ? m.end.timeFormat("yyyy-MM-dd HH:mm:ss") : null,
          };
        }),
        teamId: this.teamValue,
        WageTypeId: this.specialForm.hourlyWage,
        WageTypeOverId: this.specialForm.hourlyWageOver,
        IsWork: this.specialForm.specialType,
        LastCheckOutTime: this.specialForm.LastCheckOutTime
          ? this.specialForm.LastCheckOutTime.timeFormat("yyyy-MM-dd HH:mm:ss")
          : null,
        IsMorrow: this.specialForm.IsMorrow,
        SeriousLateMinute: this.specialForm.seriousLateMinuteCheck
          ? this.specialForm.seriousLateMinute
          : null,
        SeriousQuitMinute: this.specialForm.seriousQuitMinuteCheck
          ? this.specialForm.seriousQuitMinute
          : null,
        AbsenteeismLateMinute: this.specialForm.absenteeismMinuteCheck
          ? this.specialForm.absenteeismLateMinute
          : null,
        AbsenteeismQuitMinute: this.specialForm.absenteeismMinuteCheck
          ? this.specialForm.absenteeismQuitMinute
          : null,
      };
      this.$http
        .post("/Attendance/PlanBC/SaveSpecialPlanBC.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.$message({
              showClose: true,
              message: "设置成功",
              type: "success",
            });
            this.$emit("success");
            this.$modal.hide("special");
          }
        })
        .finally(() => (this.saveBtnLoading = false));
    },
    /**
     * 关闭弹窗
     */
    closed() {
      Object.keys(this.specialForm).forEach((m) => {
        if (m == "timeZone") {
          this.specialForm[m] = "China Standard Time";
        } else if (m == "specialType") {
          this.specialForm[m] = true;
        } else {
          this.specialForm[m] = null;
        }
      });
    },
    /**
     * 打开弹窗回调
     */
    opened() {
      this.$nextTick(() => {
        this.specialForm.timeInterval = [{}];
        if (!this.selSpecialDayData.Id) {
          return;
        }
        this.specialForm.hourlyWage = this.selSpecialDayData.WageTypeId;
        this.specialForm.hourlyWageOver = this.selSpecialDayData.WageTypeOverId;
        this.specialForm.specialType = this.selSpecialDayData.IsWork;
        this.specialForm.timeZone = this.selSpecialDayData.TimeZone;
        this.specialForm.startWork = this.selSpecialDayData.CheckInTime;
        this.specialForm.endWork = this.selSpecialDayData.CheckOutTime;
        this.specialForm.timeInterval =
          this.selSpecialDayData.FreeTimes &&
          this.selSpecialDayData.FreeTimes.length
            ? this.selSpecialDayData.FreeTimes.map((m) => {
                return {
                  start: m.StartTime,
                  end: m.EndTime,
                };
              })
            : [];
        this.specialForm.lateWork = this.selSpecialDayData.CheckInDuration;
        this.specialForm.leaveEarly = this.selSpecialDayData.CheckOutDuration;
        if (this.selSpecialDayData.IsSetCheckInElasticity) {
          this.specialForm.lateWorkRadio = 1;
        } else if (this.selSpecialDayData.IsSetCheckInHumanization) {
          this.specialForm.lateWorkRadio = 2;
        }
        if (this.selSpecialDayData.IsSetCheckOutElasticity) {
          this.specialForm.leaveEarlyRadio = 1;
        } else if (this.selSpecialDayData.IsSetCheckOutHumanization) {
          this.specialForm.leaveEarlyRadio = 2;
        }
        this.specialForm.LastCheckOutTime =
          this.selSpecialDayData.LastCheckOutTime;
        this.specialForm.IsMorrow = this.selSpecialDayData.IsMorrow;

        this.specialForm.seriousLateMinute =
          this.selSpecialDayData.SeriousLateMinute;
        this.specialForm.seriousQuitMinute =
          this.selSpecialDayData.SeriousQuitMinute;
        this.specialForm.absenteeismLateMinute =
          this.selSpecialDayData.AbsenteeismLateMinute;
        this.specialForm.absenteeismQuitMinute =
          this.selSpecialDayData.AbsenteeismQuitMinute;

        this.specialForm.seriousLateMinuteCheck =
          this.specialForm.seriousLateMinute != null;
        this.specialForm.seriousQuitMinuteCheck =
          this.specialForm.seriousQuitMinute != null;
        this.specialForm.absenteeismMinuteCheck =
          this.specialForm.absenteeismLateMinute != null ||
          this.specialForm.absenteeismQuitMinute != null;
      });
    },
    /**
     * 清除时间区间
     */
    cancleInterval(ind) {
      this.specialForm.timeInterval = this.specialForm.timeInterval.filter(
        (m, index) => ind != index
      );
    },
    /**
     * 添加时间区间
     */
    addTimeInterval() {
      this.specialForm.timeInterval.push({
        start: null,
        end: null,
      });
    },
  },
};
